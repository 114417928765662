import { Component } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { AppService } from '../../service/app.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrl: './progress.component.less',
})
export class ProgressComponent {
  isLoading$: Observable<boolean>;

  constructor(appService: AppService) {
    this.isLoading$ = appService.appStore$.pipe(select(store => store.isLoading));
  }
}
