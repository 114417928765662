import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

declare module '@angular/forms' {
  interface FormGroup {
    validate(): void;
  }
}

FormGroup.prototype.validate = function (this: FormGroup): void {
  for (const key in this.controls) {
    const formElement = this.get(key);
    if (formElement instanceof FormControl) {
      formElement.updateValueAndValidity();
    } else if (formElement instanceof FormGroup) {
      formElement.validate();
    } else if (formElement instanceof FormArray) {
      formElement.controls.forEach(control => {
        if (control instanceof FormGroup) {
          control.validate();
        } else if (control instanceof FormControl) {
          control.updateValueAndValidity();
        }
      });
    }
  }
};

export class ArrayValidators {

  // max length
  public static maxLength(max: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return;
      return control.length > max ? { maxLength: true } : null;
    }
  }

  // min length
  public static minLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return;
      return control.length < min ? { minLength: true } : null;
    }
  }
}