<form
  nz-form
  [nzLayout]="validateForm.controls.formLayout.value"
  [formGroup]="validateForm"
>
  <div class="page-container">
    <div nz-row class="page-container-head">
      <div nz-col nzSpan="12">
        <h2 *ngIf="isCreation">Create Facet</h2>
        <h2 *ngIf="!isCreation">Edit Facet</h2>
      </div>
      <div nz-col nzSpan="12" class="page-container-toolbar">
        <button *ngIf="!isCreation" nz-button nzType="primary">
          <span nz-icon nzType="ng-zorro:remove"></span>
          Remove
        </button>
        <button (click)="submitForm()" *ngIf="!isCreation" nz-button nzType="primary">
          <span nz-icon nzType="ng-zorro:add"></span>
          Save
        </button>
        <button
          (click)="submitForm()"
          *ngIf="isCreation"
          nz-button
          nzType="primary"
        >
          <span nz-icon nzType="ng-zorro:add"></span>
          Create
        </button>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>General Info</h2>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Name</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    formControlName="name"
                    placeholder="Category"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Code</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    formControlName="code"
                    [formControl]="getControl('code')"
                    placeholder="Code"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Visibility</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <nz-switch></nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Values</h2>
        </div>
        <div
          *ngFor="let item of facetValues.controls; let index = index"
          nz-col
          nzSpan="24"
        >
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Name</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="getFacetValueControl(index, 'name')"
                    placeholder="Value"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Code</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="getFacetValueControl(index, 'code')"
                    placeholder="Value"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <button (click)="removeFacetValue(index)" nz-button nzType="primary">
                <span nz-icon nzType="ng-zorro:add"></span>
                Remove
              </button>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <div nz-col nzSpan="8">
            <button (click)="addNewFacetValue()" nz-button nzType="primary">
              <span nz-icon nzType="ng-zorro:add"></span>
              Add New
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
