<div>
  <p>button-alpha</p>
  <button nz-button nzType="primary">Button Alpha</button>
</div>
<div>
  <p>button-beta</p>
  <button nz-button nzType="primary">
    <span nz-icon nzType="ng-zorro:add"></span>
    Button Beta
  </button>
</div>
<div>
  <p>button-gamma</p>
  <button nz-button nzType="primary" ngClass="gamma">
    <span nz-icon nzType="search"></span>
    Button Gamma
  </button>
</div>
<div>
  <p>button-delta</p>
  <button nz-button nzType="primary" ngClass="delta">Button Delta</button>
  <button nz-button nzType="primary" ngClass="delta inverse">
    Button Delta
  </button>
</div>
<div>
  <p>button-epsilon</p>
  <button nz-button nzType="primary" ngClass="epsilon">
    <span nz-icon nzType="search"></span>
    Button Epsilon
  </button>
</div>

<div>
  <p>input-alpha</p>
  <input nz-input placeholder="Input Alpha" />
</div>

<div>
  <p>input-alpha</p>
  <nz-input-group [nzSuffix]="suffixIconSearch">
    <input type="text" nz-input placeholder="Input Beta" />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <span nz-icon nzType="search"></span>
  </ng-template>
</div>

<div>
  <p>select-alpha</p>
  <nz-select ngModel="lucy">
    <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
    <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option>
  </nz-select>
</div>

<div>
  <p>select-beta</p>
  <nz-select ngModel="lucy" ngClass="beta">
    <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
    <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option>
  </nz-select>
</div>

<div>
  <p>chip-alpha</p>
  <nz-tag nzMode="closeable" ngClass="alpha">Value 1</nz-tag>
</div>

<div>
  <p>checkbox-alpha</p>
  <label nz-checkbox>Checkbox</label>
</div>

<div>
  <p>switch-alpha</p>
  <nz-switch></nz-switch>
</div>

<div>
  <p>app-search-box-alpha</p>
  <app-search-box-alpha></app-search-box-alpha>
</div>
