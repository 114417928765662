import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-layout-alpha',
  templateUrl: './layout-alpha.component.html',
  styleUrls: ['./layout-alpha.component.less'],
})
export class LayoutAlphaComponent {
  isHovering = false;
  isMenuVisible = false;

  timeoutRef: any;



  onMouseEnter() {
    clearTimeout(this.timeoutRef); // Clear existing timeout if any
    this.isHovering = true; // Remove 'fold' immediately on hover
  }

  onMouseLeave() {
    this.timeoutRef = setTimeout(() => {
      this.isHovering = false; // Add 'fold' back after 5 seconds
    }, 5000);
  }

  closeMenu() {
    this.isMenuVisible = false;
  }

  openMenu() {
    this.isMenuVisible = true;
  }
}
