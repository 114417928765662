
import { Component } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { LoginGQL, RegisterGQL } from '../../gql/shop/generated';
import { AppService } from '../../service/app.service';
import { strongPasswordPattern } from '../../utils/regex/password.regex';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.less',
})
export class RegisterComponent {
  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>;
    email: FormControl<string>;
    password: FormControl<string>;
    country: FormControl<string>;
    name: FormControl<string>;
    surname: FormControl<string>;
    shopName: FormControl<string>;
    eula: FormControl<boolean>;
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8), strongPasswordPattern()]],
    country: ['', [Validators.required]],
    name: ['', [Validators.required]],
    surname: ['', [Validators.required]],
    shopName: ['', [Validators.required]],
    eula: [true, [Validators.requiredTrue]]
  });

  passwordVisible = false;
  formSubmitted = false;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private loginGQL: LoginGQL,
    private registerGQL: RegisterGQL,
    private router: Router,
    public appService: AppService
  ) {
  }

  submitForm() {
    this.appService.showProgress();
    this.validateForm.markAllAsTouched();
    Object.keys(this.validateForm.controls).forEach((key) => {
      const control = this.validateForm.controls[key];
      control.updateValueAndValidity(); // Ensure the errors are up-to-date
    });

    if (this.validateForm.valid) {
      const email = this.validateForm.controls.email.value;
      const password = this.validateForm.controls.password.value;
      const name = this.validateForm.controls.name.value;
      const surname = this.validateForm.controls.surname.value;
      const shopName = this.validateForm.controls.shopName.value;

      this.registerGQL.mutate({
        authenticationRegisterInput: {
          email,
          name,
          surname,
          channelName: shopName,
          password
        }
      }).subscribe((response) => {
        this.appService.hideProgress();
        this.formSubmitted = true;
      });
    }
  }

  onGoogleClick() {
    this.appService.showProgress();
  }

  onToken(token: string) {
    this.loginGQL.mutate({
      authenticationInput: {
        google: {
          token: token
        }
      }
    }).subscribe((response) => {
      this.router.navigate(['/admin']);
      this.appService.hideProgress();
    });
  }

  onTermsClick() {
    console.log("terms");
  }
}
