import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryCache, from } from '@apollo/client';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { environment } from '../environments/environment';
import { OrganismModule } from '../organism/organism.module';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ApolloModule,
    HttpClientModule,
    AppRoutingModule,
    OrganismModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: from([
            createUploadLink({
              uri: environment.shopAPI,
              credentials: 'include'
            }),
          ])
        };
      },
      deps: [HttpLink],
    }
  ],
  exports: [
    HttpClientModule,
    OrganismModule
  ]
})
export class AppModule { }
