import { NgModule } from '@angular/core';
import { AtomModule } from '../atom/atom.module';
import { LayoutAlphaComponent } from '../layout/layout-alpha/layout-alpha.component';
import { LayoutBetaComponent } from '../layout/layout-beta/layout-beta.component';
import { GoogleSigninComponent } from './google-signin/google-signin.component';
import { NotificationButtonAlphaComponent } from './notification-button-alpha/notification-button-alpha.component';
import { ProfileButtonAlphaComponent } from './profile-button-alpha/profile-button-alpha.component';
import { SearchBoxAlphaComponent } from './search-box-alpha/search-box-alpha.component';


const Components = [
  LayoutAlphaComponent,
  LayoutBetaComponent,
  GoogleSigninComponent,
  ProfileButtonAlphaComponent,
  NotificationButtonAlphaComponent,
  SearchBoxAlphaComponent
];

@NgModule({
  declarations: [
    ...Components
  ],
  imports: [
    AtomModule,
  ],
  providers: [],
  exports: [
    ...Components,
    AtomModule,
  ],
})
export class MoleculeModule { }
