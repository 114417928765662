<div class="page-container">
  <div nz-row class="page-container-head">
    <div nz-col nzSpan="12">
      <h2>Facets</h2>
    </div>
    <div nz-col nzSpan="12" class="page-container-toolbar">
      <button [routerLink]="['/admin/facets/new']" nz-button nzType="primary">
        <span nz-icon nzType="ng-zorro:add"></span>
        Create Facet
      </button>
    </div>
  </div>
  <div class="page-container-inner tab-container">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-table
          #rowSelectionTable
          #sortTable
          nzShowSizeChanger
          [nzData]="facets.items"
          [nzFrontPagination]="false"
          [nzLoading]="$isLoading | async"
          [nzTotal]="facets.totalItems"
          (nzQueryParams)="onQueryParamsChange($event)"
          class="product-table"
        >
          <thead>
            <tr>
              <th
                [(nzChecked)]="checked"
                [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event)"
              ></th>
              <th>NAME</th>
              <th>CODE</th>
              <th>VISIBILITY</th>
              <th>VALUES</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of facets.items || []">
              <td
                [nzChecked]="setOfCheckedId.has(data.id)"
                (nzCheckedChange)="onItemChecked(data.id, $event)"
              ></td>
              <td (click)="goToFacet(data.id)" class="product-table-title">
                {{ data.name }}
              </td>
              <td>{{ data.code }}</td>
              <td>{{ data.isPrivate }}</td>
              <td>
                <nz-tag *ngFor="let tag of data.values">
                  {{ tag.name }}
                </nz-tag>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <div class="pagination-info">
          <p>
            Showing {{ pageSize * (pageIndex - 1) }} -
            {{ pageSize * pageIndex }} of {{ facets.totalItems }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
