import { bootstrapApplication } from '@angular/platform-browser';
import { devTools } from '@ngneat/elf-devtools';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import './utils/angular-patch/form-validation';

devTools({
  name: 'Shopzo Store'
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
