<div
  class="notification-button"
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="notificationDropDown"
  [nzPlacement]="'bottomRight'"
>
  <span nz-icon nzType="ng-zorro:notification"></span>
  <span class="badge"></span>
</div>

<nz-dropdown-menu #notificationDropDown="nzDropdownMenu">
  <div class="notification-dropdown-container">
    <div class="notification-dropdown-container-header">
      <h2>Alerts</h2>
      <div class="notification-tools">
        <div class="unread-only">
          <span nz-icon nzType="ng-zorro:unread-only"></span>
        </div>
        <div class="check-unread">
          <span nz-icon nzType="ng-zorro:check-unread"></span>
        </div>
      </div>
    </div>
    <div class="notification-dropdown-container-items">
      <div class="notification-dropdown-container-items-item">
        <div class="status"></div>
        <div class="content">
          <div class="header">
            <div class="event-collection-title">Permissions</div>
            <div class="date">Feb 22 at 1:24 PM</div>
          </div>
          <div class="title">New collaborator request for your store</div>
          <div class="message">
            Review collaborator request from hello&#64;shopzo.com
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
