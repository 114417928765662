<div class="login">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" [nzLg]="12" class="form-container-col">
      <div class="form-container">
        <form
          nz-form
          [nzLayout]="validateForm.controls.formLayout.value"
          [formGroup]="validateForm"
          (ngSubmit)="submitForm()"
        >
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <h1>Welcome back</h1>
              <p>Don’t have an account? <a href="/register">Sign up</a></p>
            </div>
          </div>
          <div>
            <div nz-row>
              <app-google-signin
                (buttonClick)="onGoogleClick()"
                [text]="'Log in with Google'"
                (token)="onToken($event)"
              ></app-google-signin>
            </div>
            <nz-divider nzText="or"></nz-divider>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Email</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <input
                      nz-input
                      formControlName="email"
                      placeholder="bonnie@shopzo.com"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Password</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <nz-input-group [nzSuffix]="suffixTemplate">
                      <input
                        formControlName="password"
                        [type]="passwordVisible ? 'text' : 'password'"
                        nz-input
                        placeholder="Password"
                      />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <span
                        nz-icon
                        [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                        (click)="passwordVisible = !passwordVisible"
                      ></span>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <a href="" class="forgot-password">Forgot password?</a>
                </nz-form-item>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="submit"
            nz-button
            nzType="primary"
            [nzLoading]="appService.isLoading$ | async"
          >
            Login
          </button>
        </form>
      </div>
    </div>
    <div nz-col [nzSpan]="0" [nzLg]="12">
      <img src="/assets/images/illustration-1.svg" alt="" />
    </div>
  </div>
</div>
