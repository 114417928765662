import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { MoleculeModule } from '../molecule/molecule.module';
import { DesignSystemComponent } from './design-system/design-system.component';
import { FacetDetailComponent } from './facet-detail/facet-detail.component';
import { FacetsComponent } from './facets/facets.component';
import { LoginComponent } from './login/login.component';
import { NotFoundAlphaComponent } from './not-found-alpha/not-found-alpha.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { RegisterComponent } from './register/register.component';


@NgModule({
  declarations: [
    NotFoundAlphaComponent,
    DesignSystemComponent,
    LoginComponent,
    RegisterComponent,
    ProductsComponent,
    ProductDetailComponent,
    FacetsComponent,
    FacetDetailComponent
  ],
  imports: [
    MoleculeModule,
    QuillModule.forRoot({
      theme: 'bubble'
    }),
  ],
  providers: [],
  exports: [
    MoleculeModule,
  ]
})
export class OrganismModule { }