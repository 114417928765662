import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchResultItem, SearchService } from '../../service/search.service';

@Component({
  selector: 'app-search-box-alpha',
  templateUrl: './search-box-alpha.component.html',
  styleUrl: './search-box-alpha.component.less',
})
export class SearchBoxAlphaComponent {
  searchControl = new FormControl();
  hasResult = false;
  activeFilters: { [key: string]: boolean } = {
    products: false,
    orders: false,
    settings: false,
  };
  results: SearchResultItem[] = [];

  constructor(public searchService: SearchService) {}

  search(query: string) {
    console.log({ query });
    if (query.length < 3) {

      this.hasResult = false;
      console.log({ result: this.hasResult })
      return;
    }

    this.searchService.search(query);
  }

  toggleFilter(filter: string) {
    this.activeFilters[filter] = !this.activeFilters[filter];
  }

}
