<app-progress></app-progress>
<ng-template #menuTemplate>
  <ul nz-menu nzMode="inline" class="nz-menu-container">
    <li nz-submenu nzTitle="Catalog" nzOpen>
      <ul>
        <li nz-menu-item>
          <a [routerLink]="['/admin/products']">
            <span nz-icon nzType="ng-zorro:price-tag"></span>
            Products
          </a>
        </li>
        <li nz-menu-item>
          <a [routerLink]="['/admin/facets']">
            <span nz-icon nzType="ng-zorro:facets"></span>
            Facets
          </a>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:collections"></span>
          Collections
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:assets"></span>
          Assets
        </li>
      </ul>
    </li>
  </ul>
</ng-template>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isMenuVisible"
  [nzPlacement]="'left'"
  (nzOnClose)="closeMenu()"
>
  <ng-container *nzDrawerContent>
    <nz-sider class="menu-sidebar">
      <button (click)="closeMenu()">Close</button>

      <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
    </nz-sider>
  </ng-container>
</nz-drawer>

<div class="header">
  <div class="sidebar-logo hidden-md-down">
    <a href="https://dev.shopzo.com" target="_blank">
      <img src="assets/images/logo.png" alt="logo" />
    </a>
  </div>
  <span nz-icon (click)="openMenu()" class="hidden-md-up menu-button">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.75 7C20.75 7.41421 20.4142 7.75 20 7.75L4 7.75C3.58579 7.75 3.25 7.41421 3.25 7C3.25 6.58579 3.58579 6.25 4 6.25L20 6.25C20.4142 6.25 20.75 6.58579 20.75 7Z"
        fill="#1D2939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12Z"
        fill="#1D2939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.75 17C20.75 17.4142 20.4142 17.75 20 17.75L4 17.75C3.58579 17.75 3.25 17.4142 3.25 17C3.25 16.5858 3.58579 16.25 4 16.25L20 16.25C20.4142 16.25 20.75 16.5858 20.75 17Z"
        fill="#1D2939"
      />
    </svg>
  </span>

  <div class="search-box-container">
    <app-search-box-alpha></app-search-box-alpha>
  </div>

  <div class="information-container">
    <div class="notification-container">
      <app-notification-button-alpha></app-notification-button-alpha>
    </div>
    <div class="profile-container">
      <app-profile-button-alpha></app-profile-button-alpha>
    </div>
  </div>
</div>

<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar fold hidden-md-down"
    [ngClass]="{ fold: !isHovering }"
    nzCollapsible
    nzBreakpoint="md"
    [nzTrigger]="null"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
  </nz-sider>
  <nz-layout class="right-layout">
    <nz-content>
      <router-outlet></router-outlet>
      <p>shopzo-rocks!</p>
    </nz-content>
  </nz-layout>
</nz-layout>
