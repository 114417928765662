<button type="button" (click)="login()" class="ant-btn">
  <span nz-icon>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Google " clip-path="url(#clip0_999_319)">
        <g id="Vector">
          <path
            d="M19.1667 10.9002C19.1667 10.2646 19.1152 9.62546 19.0052 9.00009H10.1847V12.6011H15.2358C15.0262 13.7625 14.3527 14.7899 13.3666 15.4428V17.7793H16.38C18.1496 16.1506 19.1667 13.7453 19.1667 10.9002Z"
            fill="#4285F4"
          />
          <path
            d="M10.1847 20.0368C12.7068 20.0368 14.8338 19.2087 16.3834 17.7793L13.37 15.4428C12.5316 16.0132 11.4492 16.3362 10.1882 16.3362C7.74852 16.3362 5.67999 14.6903 4.93779 12.4774H1.82812V14.8861C3.4156 18.0439 6.64897 20.0368 10.1847 20.0368Z"
            fill="#34A853"
          />
          <path
            d="M4.93436 12.4774C4.54265 11.316 4.54265 10.0584 4.93436 8.89699V6.48829H1.82813C0.501795 9.13065 0.501786 12.2438 1.82812 14.8861L4.93436 12.4774Z"
            fill="#FBBC04"
          />
          <path
            d="M10.1847 5.03481C11.5179 5.01419 12.8065 5.51586 13.772 6.43673L16.4419 3.76688C14.7513 2.17941 12.5075 1.30664 10.1847 1.33413C6.64897 1.33413 3.41561 3.32708 1.82813 6.48829L4.93436 8.89699C5.67313 6.68071 7.74508 5.03481 10.1847 5.03481Z"
            fill="#EA4335"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_999_319">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
  {{ text }}
</button>
