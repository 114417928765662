import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyGQL } from '../../gql/shop/generated';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrl: './verify.component.less',
})
export class VerifyComponent implements OnInit {
  token = '';

  constructor(
    private route: ActivatedRoute,
    private verifyGQL: VerifyGQL,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'] || '';
      this.verifyGQL.mutate({
        token: this.token
      }).subscribe((response) => {
        if (response.data?.verify?.success) {
          this.router.navigate(['/admin']);
        }
      });
    });
  }
}
