import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { tap } from 'rxjs/operators';
import { AddOptionGroupToProductGQL, CreateProductGQL, CreateProductInput, CreateProductOptionGQL, CreateProductOptionGroupGQL, CreateProductOptionGroupInput, CreateProductOptionInput, CreateProductVariantInput, CreateProductVariantsGQL, LogicalOperator, Product, ProductGQL, ProductList, ProductListOptions, ProductsGQL, SortOrder, UpdateProductGQL, UpdateProductInput, UpdateProductOptionGQL, UpdateProductOptionGroupGQL, UpdateProductOptionGroupInput, UpdateProductOptionInput, UpdateProductVariantInput, UpdateProductVariantsGQL } from '../gql/shop/generated';
import { AppService } from './app.service';


interface ProductsState {
  products: ProductList;
  params: ProductListOptions;
  currentPage: number;
  pageSize: number;
  product: Product | undefined
}

const initialState = withProps<ProductsState>({
  product: undefined,
  products: {
    items: [],
    totalItems: 0
  },
  params: {
    filter: undefined,
    filterOperator: LogicalOperator.AND,
    skip: 0,
    take: 10,
    sort: undefined,
  },
  currentPage: 1,
  pageSize: 10
});

@Injectable({ providedIn: 'root' })
export class ProductService {
  private store = createStore({ name: 'product' }, initialState);

  products$ = this.store.pipe(select(state => state.products));
  product$ = this.store.pipe(select(state => state.product));
  currentPage$ = this.store.pipe(select(state => state.currentPage));
  pageSize$ = this.store.pipe(select(state => state.pageSize));

  constructor(
    private productsGQL: ProductsGQL,
    private appService: AppService,
    private productGQL: ProductGQL,
    private createProductOptionGroupGQL: CreateProductOptionGroupGQL,
    private updateProductOptionGroupGQL: UpdateProductOptionGroupGQL,
    private createProductOptionGQL: CreateProductOptionGQL,
    private updateProductOptionGQL: UpdateProductOptionGQL,
    private createProductVariantsGQL: CreateProductVariantsGQL,
    private updateProductVariantsGQL: UpdateProductVariantsGQL,
    private createProductGQL: CreateProductGQL,
    private updateProductGQL: UpdateProductGQL,
    private addOptionGroupToProductGQL: AddOptionGroupToProductGQL,
  ) { }
  addOptionGroupToProduct(optionGroupId: string, productId: string) {
    return this.appService.withLoader(() =>
      this.addOptionGroupToProductGQL.mutate({ optionGroupId, productId })
    );
  }

  updateProduct(input: UpdateProductInput) {
    return this.appService.withLoader(() =>
      this.updateProductGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  createProduct(input: CreateProductInput) {
    return this.appService.withLoader(() =>
      this.createProductGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  createProductOptionGroup(input: CreateProductOptionGroupInput) {
    return this.appService.withLoader(() =>
      this.createProductOptionGroupGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  updateProductOptionGroup(input: UpdateProductOptionGroupInput) {
    return this.appService.withLoader(() =>
      this.updateProductOptionGroupGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  createProductOption(input: CreateProductOptionInput) {
    return this.appService.withLoader(() =>
      this.createProductOptionGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  updateProductOption(input: UpdateProductOptionInput) {
    return this.appService.withLoader(() =>
      this.updateProductOptionGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  createProductVariants(input: CreateProductVariantInput | CreateProductVariantInput[]) {
    return this.appService.withLoader(() =>
      this.createProductVariantsGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  updateProductVariants(input: UpdateProductVariantInput | UpdateProductVariantInput[]) {
    return this.appService.withLoader(() =>
      this.updateProductVariantsGQL.mutate({ input }).pipe(
        tap({
          next: (response) => {
            console.log(response.errors);
          }
        })
      )
    );
  }

  fetchProducts() {
    const params = this.store.getValue().params;

    return this.appService.withLoader(() =>
      this.productsGQL.fetch({ options: params }).pipe(
        tap({
          next: (response) => {
            this.store.update(state => ({
              ...state,
              products: response.data.products as ProductList,
              loading: false
            }));
          },
          error: (error) => {
            console.error('Failed to fetch products:', error);
            this.store.update(state => ({
              ...state,
              loading: false
            }));
          }
        })
      )
    );
  }

  fetchProduct(id: string) {
    return this.appService.withLoader(() =>
      this.productGQL.fetch({ productId: id }).pipe(
        tap({
          next: (response) => {
            const product = response.data.product as Product;
            console.log({ product });

            this.store.update(state => ({
              ...state,
              product
            }));
          },
          error: (error) => {
            console.error('Failed to fetch products:', error);
            this.store.update(state => ({
              ...state,
              loading: false
            }));
          }
        })
      )
    );
  }

  updatePagination(page: number, pageSize: number) {
    this.store.update(state => ({
      ...state,
      currentPage: page,
      pageSize: pageSize,
      params: {
        ...state.params,
        skip: (page - 1) * pageSize
      }
    }));
  }

  updateSort(sortField: string, sortOrder: string) {
    this.store.update(state => ({
      ...state,
      params: {
        sort: {
          [sortField]: SortOrder.ASC
        }
      }
    }));
  }

  goToPage(page: number) {
    this.updatePagination(page, this.store.getValue().pageSize);
    return this.fetchProducts();
  }

  nextPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(currentPage + 1);
  }

  previousPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(Math.max(1, currentPage - 1));
  }

  clearProduct() {
    this.store.update(state => ({
      ...state,
      product: undefined
    }));
  }
}