import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-layout-beta',
  templateUrl: './layout-beta.component.html',
  styleUrls: ['./layout-beta.component.less'],
})
export class LayoutBetaComponent {
  isCollapsed = false;
  isVisible = false;
}
