import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../environments/environment';

declare global {
  interface Window {
    google: any;
  }
}

@Component({
  selector: 'app-google-signin',
  templateUrl: './google-signin.component.html',
  styleUrl: './google-signin.component.less',
})
export class GoogleSigninComponent {
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  @Output() token: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() text = 'Sign up with Google';

  login = () => {
    this.buttonClick.emit();
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (response) => {
        this.token.emit(response.credential);
      }
    });
    window.google.accounts.id.renderButton(googleLoginWrapper, { theme: "outline", size: "large" });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      'div[role=button]'
    ) as HTMLElement;

    googleLoginWrapperButton?.click();
  };
}
