import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { tap } from 'rxjs/operators';
import { CreateFacetGQL, CreateFacetInput, CreateFacetValueInput, CreateFacetValuesGQL, Facet, FacetGQL, FacetList, FacetListOptions, FacetsGQL, FacetValueList, FacetValueListOptions, FacetValuesGQL, InputMaybe, LogicalOperator, SortOrder, UpdateFacetGQL, UpdateFacetInput, UpdateFacetValueInput, UpdateFacetValuesGQL } from '../gql/shop/generated';
import { AppService } from './app.service';


interface FacetesState {
  facets: FacetList;
  params: FacetListOptions;
  currentPage: number;
  pageSize: number;
  facet: Facet | undefined;
  facetValues: FacetValueList;
}

const initialState = withProps<FacetesState>({
  facets: {
    items: [],
    totalItems: 0
  },
  params: {
    filter: undefined,
    filterOperator: LogicalOperator.AND,
    skip: 0,
    take: 10,
    sort: undefined,
  },
  currentPage: 1,
  pageSize: 10,
  facet: undefined,
  facetValues: {
    items: [],
    totalItems: 0
  }
});

@Injectable({ providedIn: 'root' })
export class FacetService {
  private store = createStore({ name: 'facet' }, initialState);

  facets$ = this.store.pipe(select(state => state.facets));
  facet$ = this.store.pipe(select(state => state.facet));
  currentPage$ = this.store.pipe(select(state => state.currentPage));
  pageSize$ = this.store.pipe(select(state => state.pageSize));
  facetValues$ = this.store.pipe(select(state => state.facetValues));

  constructor(
    private facetsGQL: FacetsGQL,
    private appService: AppService,
    private createFacetGQL: CreateFacetGQL,
    private facetGQL: FacetGQL,
    private facetValuesGQL: FacetValuesGQL,
    private updateFacetGQL: UpdateFacetGQL,
    private updateFacetValuesGQL: UpdateFacetValuesGQL,
    private createFacetValuesGQL: CreateFacetValuesGQL
  ) { }

  fetchFacetValues(options?: InputMaybe<FacetValueListOptions>) {
    this.facetValuesGQL.fetch({
      options
    }).pipe(
      tap({
        next: (response) => {
          console.log(response.data.facetValues);
          
          this.store.update(state => ({
            ...state,
            facetValues: response.data.facetValues as FacetValueList
          }));
        }
      })
    ).subscribe();
  }

  fetchFacets() {
    const params = this.store.getValue().params;
    this.store.update(state => ({
      ...state,
      loading: true
    }));

    this.appService.showProgress();
    this.facetsGQL.fetch({ options: params })
      .pipe(
        tap({
          next: (response) => {
            this.store.update(state => ({
              ...state,
              facets: response.data.facets as FacetList,
              loading: false
            }));
            this.appService.hideProgress();
          }
        })
      )
      .subscribe();
  }

  updatePagination(page: number, pageSize: number) {
    this.store.update(state => ({
      ...state,
      currentPage: page,
      pageSize: pageSize,
      params: {
        ...state.params,
        skip: (page - 1) * pageSize
      }
    }));
  }

  updateSort(sortField: string, sortOder: string) {
    this.store.update(state => ({
      ...state,
      params: {
        sort: {
          [sortField]: SortOrder.ASC
        }
      }
    }));
  }

  goToPage(page: number) {
    this.store.update(state => ({
      ...state,
      currentPage: page,
      params: {
        ...state.params,
        skip: (page - 1) * state.pageSize
      }
    }));
    this.fetchFacets();
  }

  nextPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(currentPage + 1);
  }

  previousPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(Math.max(1, currentPage - 1));
  }

  createFacet(input: CreateFacetInput) {
    this.createFacetGQL.mutate({ input }).subscribe((response) => {
      console.log({ response });
    })
  }

  createFacetValues(input: CreateFacetValueInput | CreateFacetValueInput[]) {
    this.createFacetValuesGQL.mutate({
      createFacetValuesInput: input
    }).pipe(
      tap({
        next: (response) => {
          this.appService.hideProgress();
          return response;
        }
      })
    ).subscribe()
  }

  updateFacetValues(input: UpdateFacetValueInput | UpdateFacetValueInput[]) {
    this.updateFacetValuesGQL.mutate({
      updateFacetValuesInput: input
    }).pipe(
      tap({
        next: (response) => {
          this.appService.hideProgress();
          return response;
        }
      })
    )
      .subscribe();
  }

  updateFacet(input: UpdateFacetInput) {
    this.updateFacetGQL.mutate({
      updateFacetInput: input
    }).pipe(
      tap({
        next: (response) => {
          this.appService.hideProgress();
          return response;
        }
      })
    ).subscribe();
  }

  fetchFacet(id: string) {
    this.facetGQL.fetch({
      facetId: id
    }).pipe(
      tap({
        next: (response) => {
          this.store.update(state => ({
            ...state,
            facet: response.data.facet as Facet
          }));
        }
      })
    ).subscribe();
  }

  clearFacet() {
    this.store.update(state => ({
      ...state,
      facet: undefined
    }))
  }
}