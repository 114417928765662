import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-button-alpha',
  templateUrl: './profile-button-alpha.component.html',
  styleUrl: './profile-button-alpha.component.less',
})
export class ProfileButtonAlphaComponent {
  isProfileVisible = false;

  closeProfile() {
    this.isProfileVisible = false;
  }

  openProfile() {
    this.isProfileVisible = true;
  }
}
