<nz-select
  nzShowSearch
  [nzDropdownRender]="customTemplate"
  nzAllowClear
  nzPlaceHolder="Search"
  nzSuffixIcon="search"
  class="search-select"
  (nzOnSearch)="search($event)"
  [nzServerSearch]="true"
>
  <nz-option
    *ngFor="let item of hasResult ? [1] : []"
    [nzLabel]="item"
    [nzValue]="item"
  ></nz-option>
</nz-select>

<ng-template #customTemplate>
  <div *ngIf="hasResult" class="dropdown-content">
    <div class="filter-chips">
      <nz-tag
        nzMode="checkable"
        [nzChecked]="activeFilters['products']"
        (nzCheckedChange)="toggleFilter('products')"
      >
        Products ({{ 1 }})
      </nz-tag>
      <nz-tag
        nzMode="checkable"
        [nzChecked]="activeFilters['orders']"
        (nzCheckedChange)="toggleFilter('orders')"
      >
        Orders ({{ 1 }})
      </nz-tag>
      <nz-tag
        nzMode="checkable"
        [nzChecked]="activeFilters['settings']"
        (nzCheckedChange)="toggleFilter('settings')"
      >
        Settings ({{ 1 }})
      </nz-tag>
    </div>
    <nz-list>
      <nz-list-item *ngFor="let item of searchService.results$ | async">
        <div class="item-container" *ngIf="item.type === 'product'">
          <nz-list-item-meta
            nzAvatar="https://via.placeholder.com/24"
            nzTitle="{{ item.title }}"
            nzDescription="{{ item.description }}"
          ></nz-list-item-meta>
        </div>
        <div class="item-container" *ngIf="item.type === 'order'">
          <nz-list-item-meta
            [nzAvatar]="orderIcon"
            nzTitle="{{ item.title }}"
            nzDescription="Cincinnati Gateway # Placed on Feb 16, 11:11PM"
          ></nz-list-item-meta>
          <div class="order-status">
            <nz-tag nzColor="green">Paid</nz-tag>
            <div class="divider"></div>
            <nz-tag nzColor="orange">Unfulfilled</nz-tag>
          </div>
        </div>
        <div class="item-container" *ngIf="item.type === 'setting'">
          <nz-list-item-meta
            [nzAvatar]="settingIcon"
            nzTitle="{{ item.title }}"
            nzDescription="{{ item.description }}"
          ></nz-list-item-meta>
        </div>
      </nz-list-item>
    </nz-list>
  </div>
</ng-template>

<ng-template #orderIcon>
  <nz-avatar nzIcon="ng-zorro:order"></nz-avatar>
</ng-template>
<ng-template #settingIcon>
  <nz-avatar nzIcon="ng-zorro:settings"></nz-avatar>
</ng-template>
