import { Injectable } from "@angular/core";
import { createStore, withProps } from '@ngneat/elf';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { tap } from "rxjs";
import { CreateAssetInput, CreateAssetsGQL } from "../gql/shop/generated";

interface AssetState {
}

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  assetStore$ = createStore(
    { name: 'asset' },
    withProps<AssetState>({})
  );

  constructor(
    private notification: NzNotificationService,
    private createAssetsGQL: CreateAssetsGQL
  ) { }

  createAssets(input: CreateAssetInput | CreateAssetInput[]) {
    return this.createAssetsGQL.mutate({
      input,
    }).pipe(
      tap({
        next: (response) => {
          return response;
        }
      })
    );
  }

  async fetchFile(source: string, name: string) {
    const response = await fetch(source);
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    return file;
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}