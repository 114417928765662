import { Component } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { LoginGQL } from '../../gql/shop/generated';
import { AppService } from '../../service/app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.less',
})
export class LoginComponent {

  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>;
    email: FormControl<string>;
    password: FormControl<string>;
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  passwordVisible = false;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private loginGQL: LoginGQL,
    private router: Router,
    public appService: AppService
  ) { }

  submitForm() {
    this.validateForm.markAllAsTouched();
    Object.keys(this.validateForm.controls).forEach((key) => {
      const control = this.validateForm.controls[key];
      control.updateValueAndValidity(); // Ensure the errors are up-to-date
    });

    if (this.validateForm.valid) {
      const email = this.validateForm.controls.email.value;
      const password = this.validateForm.controls.password.value;
      this.appService.showProgress();
      this.loginGQL.mutate({
        authenticationInput: {
          native: {
            emailAddress: email,
            password: password
          }
        }
      }).subscribe((response) => {
        this.router.navigate(['/admin/products']);
        this.appService.hideProgress();
      });
    }
  }

  onGoogleClick() {
    this.appService.showProgress();
  }

  onToken(token: string) {
    this.loginGQL.mutate({
      authenticationInput: {
        google: {
          token: token
        }
      }
    }).subscribe((response) => {
      this.router.navigate(['/admin']);
      this.appService.hideProgress();
    });
  }
}
