import { Injectable } from "@angular/core";
import { createStore, withProps } from '@ngneat/elf';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { map, Observable, timer } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";

interface AppState {
  isLoading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  appStore$ = createStore(
    { name: 'app' },
    withProps<AppState>({ isLoading: false })
  );

  public isLoading$ = this.appStore$.pipe(map(store => store.isLoading));

  private loaderVisible = false;
  private showLoaderTimeout: any;
  private readonly minLoaderTime = 1000;
  private readonly delayBeforeShowing = 200;

  constructor(private notification: NzNotificationService) { }

  showProgress() {
    this.appStore$.update((state) => ({
      ...state,
      isLoading: true,
    }));
  }

  hideProgress() {
    this.appStore$.update((state) => ({
      ...state,
      isLoading: false,
    }));
  }

  withLoader<T>(operation: () => Observable<T>): Observable<T> {
    const showLoader$ = timer(this.delayBeforeShowing).pipe(
      tap(() => {
        if (!this.loaderVisible) {
          this.loaderVisible = true;
          this.showProgress();
        }
      })
    );

    const operation$ = operation().pipe(
      finalize(() => {
        if (this.loaderVisible) {
          setTimeout(() => {
            this.hideProgress();
            this.loaderVisible = false;
          }, this.minLoaderTime);
        }
      })
    );

    return showLoader$.pipe(switchMap(() => operation$));
  }
}