import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { tap } from 'rxjs';
import { SearchGQL, SearchResponse } from '../gql/shop/generated';


export interface ProductItem {
  title: string;
  description: string;
  type: 'product';
}

export interface SettingItem {
  title: string;
  description: string;
  type: 'setting';
}

export interface OrderItem {
  title: string;
  status: string;
  description: string;
  type: 'order';
}

export type SearchResultItem = ProductItem | OrderItem | SettingItem;

export interface SearchState {
  results: SearchResultItem[];
  response: SearchResponse;
}

@Injectable({ providedIn: 'root' })
export class SearchService {
  private store = createStore(
    { name: 'search' },
    withProps<SearchState>({
      results: [],
      response: {
        collections: [],
        facetValues: [],
        items: [],
        totalItems: 0
      }
    })
  );

  facetValues$ = this.store.pipe(select(state => state.response.facetValues));
  results$ = this.store.pipe(select(state => state.results));

  private dummyData: SearchState = {
    results: [
      { title: 'Product 1', description: 'Product 1 description', type: 'product', },
      { title: 'Product 2', description: 'Product 2 description', type: 'product', },
      { title: 'Order #I12353342', status: 'Paid', description: 'Product 1 description', type: 'order' },
      { title: 'Order #I12353342', status: 'Paid', description: 'Product 2 description', type: 'order' },
      { title: 'Setting 1', description: 'Setting 1 description', type: 'setting' },
    ],
    response: {
      collections: [],
      facetValues: [],
      items: [],
      totalItems: 0
    }
  }

  constructor(
    private searchGQL: SearchGQL
  ) { }


  search(query: string) {
    this.searchGQL.fetch({
      input: {
        term: query
      }
    }).pipe(
      tap({
        next: (response) => {
          this.store.update(state => ({
            ...state,
            results: this.dummyData.results,
            response: response.data.search as SearchResponse
          }))
        }
      })
    ).subscribe();
  }
}